<template>
  <div class="dashboard">

    <Filters :on-change="onChangeFilters" :loading="loading" @filterError="onErrorFilters" />

    <div class="row row-equal">
      <PerTime :data="timeStats" />
      <PieTime :data="pieStats" />
      <TopProducts :data="visibleList" :progressMax="progressMax" />
    </div>

    <div class="row">
      <Browsers :data="browserStats" />
      <OS :data="osStats" />
    </div>

    <div class="row">
      <RetailerStats :data="retailerStats" />
      <BrandStats :data="brandStats" />
    </div>

    <Maps :ipData="IPStats"
      :countriesData="mapStats" />

  </div>
</template>

<script>
import BubbleMapData from '../../services/data/maps/BubbleMapData';
import ElasticAPI from '../../services/API/ElasticAPI';
import fetchFilters from "../../mixins/fetchFilters";

export default {
  name: 'impressions',
  mixins: [fetchFilters],

  components: {
    Maps: ()=> import('../stats/Maps'),
    BrandStats: ()=> import('../stats/LicenseStats.vue'),
    RetailerStats: ()=> import('../stats/RetailerStats'),
    OS: ()=> import('../stats/OS'),
    Browsers: ()=> import('../stats/Browsers'),
    TopProducts: ()=> import('../stats/TopProducts'),
    PieTime: ()=> import('../stats/PieTime'),
    PerTime: ()=> import('../stats/PerTime'),
    Filters: ()=> import('../filters/Filters'),
  },

  data () {
    return {
      mapStats: {
        latlong: BubbleMapData.latlong,
        data: [],
      },
      IPStats: {
        latlong: {},
        data: [],
      },
      retailerStats: {
        labels: ['all'],
        datasets: [],
      },
      timeStats: {
        labels: ['all'],
        datasets: [],
      },
      brandStats: {
        labels: ['all'],
        datasets: [],
      },
      pieStats: {
        labels: [],
        datasets: [],
      },
      browserStats: {
        labels: [],
        datasets: [],
      },
      osStats: {
        labels: [],
        datasets: [],
      },
      visibleList: [],
    };
  },

  created () {
    if (!this.user.roles.includes('ROLE_ADMIN')) {
      this.user.retailers.forEach(retailer => {
        this.filters.retailers.push(retailer.id);
      });
      this.user.brands.forEach(brand => {
        this.filters.brands.push(brand.id);
      });
    } else {
    }

    const promiseRetailers = ElasticAPI.getRetailersStats(this.filters);
    const promiseMap = ElasticAPI.getMapStats(this.filters, BubbleMapData);
    const promiseTimeStat = ElasticAPI.getTimeStats(this.filters);
    const promiseBrandStat = ElasticAPI.getBrandStats(this.filters);
    const promisePieStat = ElasticAPI.getPieStats(this.filters);
    const promisePopularProductsStat = ElasticAPI.getPopularProductsStat(this.filters);
    const promiseDataDevices = ElasticAPI.getDevicesStats(this.filters);
    const promiseIPMap = ElasticAPI.getIPMap(this.filters);

    promiseIPMap.then(data => {
      this.IPStats = {
        latlong: data.latlong,
        data: data.datasets,
      };
    });

    promiseRetailers.then(data => {
      this.retailerStats = {
        labels: ['all'],
        datasets: data,
      };
    });

    promiseMap.then(data => {
      this.mapStats = {
        latlong: BubbleMapData.latlong,
        data: data,
      };
    });

    promiseTimeStat.then(data => {
      this.timeStats = {
        labels: data.labels,
        datasets: data.datasets,
      };
    });

    promiseBrandStat.then(data => {
      this.brandStats = {
        labels: ['all'],
        datasets: data,
      };
    });

    promisePieStat.then(data => {
      this.pieStats = {
        labels: data.labels,
        datasets: data.datasets,
      };
    });

    promisePopularProductsStat.then(data => {
      this.progressMax = data.progressMax;
      this.visibleList = data.datasets;
      this.visibleList.forEach((product, index) => {
        product.isShow = index >= 0 && index < 5;
      });
    });

    promiseDataDevices.then(data => {
      this.browserStats = {
        labels: data.browser.labels,
        datasets: data.browser.datasets,
      };

      this.osStats = {
        labels: data.os.labels,
        datasets: data.os.datasets,
      };
    });

    const promiseAll = Promise.all([
      promiseRetailers,
      promiseMap,
      promiseTimeStat,
      promiseBrandStat,
      promisePieStat,
      promisePopularProductsStat,
      promiseDataDevices,
      promiseIPMap,
    ]);

    promiseAll.then(() => {
      this.loading = false;
    });
  },

  methods: {
    onChangeFilters (data) {
      this.loading = true;
      this.filters = data;

      const promiseRetailers = ElasticAPI.getRetailersStats(this.filters);
      const promiseMap = ElasticAPI.getMapStats(this.filters, BubbleMapData);
      const promiseTimeStat = ElasticAPI.getTimeStats(this.filters);
      const promiseBrandStat = ElasticAPI.getBrandStats(this.filters);
      const promisePieStat = ElasticAPI.getPieStats(this.filters);
      const promisePopularProductsStat = ElasticAPI.getPopularProductsStat(this.filters);
      const promiseDataDevices = ElasticAPI.getDevicesStats(this.filters);
      const promiseIPMap = ElasticAPI.getIPMap(this.filters);

      promiseIPMap.then(data => {
        this.IPStats = {
          latlong: data.latlong,
          data: data.datasets,
        };
        this.$refs.ipMap.drawMap(this.IPStats);
      });

      promiseRetailers.then(data => {
        this.retailerStats = {
          labels: ['all'],
          datasets: data,
        };
      });

      promiseMap.then(data => {
        this.mapStats = {
          latlong: BubbleMapData.latlong,
          data: data,
        };
      });

      promiseTimeStat.then(data => {
        this.timeStats = {
          labels: data.labels,
          datasets: data.datasets,
        };
      });

      promiseBrandStat.then(data => {
        this.brandStats = {
          labels: ['all'],
          datasets: data,
        };
      });

      promisePieStat.then(data => {
        this.pieStats = {
          labels: data.labels,
          datasets: data.datasets,
        };
      });

      promisePopularProductsStat.then(data => {
        this.progressMax = data.progressMax;
        this.visibleList = data.datasets;
        this.visibleList.forEach((product, index) => {
          product.isShow = index >= 0 && index < 5;
        });
      });

      promiseDataDevices.then(data => {
        this.browserStats = {
          labels: data.browser.labels,
          datasets: data.browser.datasets,
        };
        this.osStats = {
          labels: data.os.labels,
          datasets: data.os.datasets,
        };
      });

      const promiseAll = Promise.all([
        promiseRetailers,
        promiseMap,
        promiseTimeStat,
        promiseBrandStat,
        promisePieStat,
        promisePopularProductsStat,
        promiseDataDevices,
        promiseIPMap,
      ]);

      promiseAll.then(() => {
        this.loading = false;
      });
    },
  },

};
</script>

<style lang="scss">
  .row-equal .flex {
    .vac-card {
      height: 100%;
    }
  }

  .dashboard {
    .vac-card {
      margin-bottom: 0 !important;
    }
  }

  .link-icon {
    color: #000000;
    transition: 0.3s;
    cursor: pointer;
    margin-left: 26px;
  }

  .link-icon:hover {
    opacity: 0.7;
  }

  .td--small {
    width: 20%;
  }

  .dashboard-contributors-list {
    flex-direction: column;
    height: 100%;
  }

  .inner-loading {
    table {
      width: 100%;
    }

    .va-table td {
      word-break: break-all;
    }

    tbody,
    thead {
      width: 100%;
    }
  }
</style>
